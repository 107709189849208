import DescriptionSiteSite from "./views/DescriptionSite.vue";
import './site.scss';
export default {
    name: "App",
    components: {
        DescriptionSiteSite
    },
    data() {
        return {
            desc: 'Abc'
        };
    },
    mounted() {
        const params = document.querySelector('#site').getAttribute('data-params');
        if (params) {
            const data = JSON.parse(params);
            if (data.description !== undefined) {
                this.desc = data.description;
            }
        }
        console.log(params);
    }
};
